<template>
  <v-tooltip left>
    <template v-slot:activator="{ on, attrs }">
      <div class="product-class" v-bind="attrs" v-on="on">
        <img aria-hidden :alt="pClass.name" :src="src" />
      </div>
    </template>
    <span>{{ pClass.name }}</span>
  </v-tooltip>
</template>
<style scoped lang="scss">
img {
  width: 25px;
  height: 25px;
}
</style>
<script>
export default {
  name: "ProductClass",
  props: ["pClass"],
  computed: {
    src() {
      return "/product-classes/" + this.pClass.iconSource;
    }
  }
};
</script>
