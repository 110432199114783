<template>
  <v-card
    outlined
    rounded="lg"
    class="product-card py-1 fill-height"
    :to="{ name: 'Product', params: { slug: product.slug } }"
  >
    <div
      class="product fill-height"
      :class="[promoClass]"
      @click="logClickProduct"
    >
      <div class="header d-flex justify-space-between align-start">
        <ProductPromo
          :warehousePromo="product.warehousePromo"
          v-if="product.warehousePromo"
        />
        <v-spacer />
        <div class="icons-box align-center">
          <ProductAcqLimit :product="product" />
          <LeadTime
            v-if="product.productInfos.LEAD_TIME"
            :time="product.productInfos.LEAD_TIME"
            :showTooltip="true"
            :isCheckout="false"
          />
          <LockCutoff
            v-if="product.productInfos.PRODUCT_LOCK_CUTOFF"
            :hours="product.productInfos.PRODUCT_LOCK_CUTOFF"
            :showTooltip="true"
            :isCheckout="false"
          />
          <AxBCutoff
            v-if="product.productInfos.PRODUCT_AXB_CUTOFF"
            :hour="product.productInfos.PRODUCT_AXB_CUTOFF"
            :showTooltip="true"
            :isCheckout="false"
          />
          <DayLock
            v-if="product.productInfos.DAY_LOCK"
            :days="product.productInfos.DAY_LOCK"
            :showTooltip="true"
            :isCheckout="false"
          />
          <v-tooltip
            bottom
            v-if="product.warehousePromo && product.warehousePromo.view.bubble"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" @click.stop.prevent=""
                >mdi-information-outline</v-icon
              >
            </template>
            <span
              class="tooltip-content-bubble"
              v-html="product.warehousePromo.view.bubble"
            ></span>
          </v-tooltip>

          <v-tooltip left :key="heartKey">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                aria-label="Aggiungi ad una lista"
                v-bind="attrs"
                v-on="on"
                icon
                @click.stop.prevent="addToFavorites"
                @mousedown.stop
              >
                <v-icon color="primary" v-if="highlight">$heartFull</v-icon>
                <v-icon v-else class="heart">$heart</v-icon>
              </v-btn>
            </template>
            <span>Aggiungi ad una lista</span>
          </v-tooltip>
        </div>
      </div>
      <div class="product-classes-wrapper">
        <ProductInfo v-if="product.productInfos.REFUND_ASL == '1'" />
        <ProductClass
          :pClass="pClass"
          v-for="pClass in productClasses"
          :key="pClass.productClassid"
        />
      </div>
      <div class="d-flex body flex-column fill-width fill-height">
        <img
          :src="product.mediaURL"
          class="product-img align-self-center"
          :width="$vuetify.breakpoint.xs ? 110 : 170"
          :height="$vuetify.breakpoint.xs ? 110 : 170"
          :alt="'Immagine' + product.name"
          :title="
            product.name + ' (' + product.codInt + '-' + product.codVar + ')'
          "
        />

        <div class="description">
          <span class="brand">{{ product.shortDescr }}</span>
          <span class="name">{{ product.name }}</span>
          <span class="descr">
            <span
              class="weight-unit"
              v-if="
                product.productInfos.TIPOLOGIA == 'Pezzo' &&
                  product.priceUmDisplay
              "
            >
              {{ $n(product.priceUmDisplay, "currency") }}/
              <span class="weightUnitDisplay"
                >{{ product.weightUnitDisplay }} -
              </span>
            </span>
            {{ product.description }}
          </span>
        </div>

        <div class="actions mt-auto">
          <ProductPrice :product="product" />
          <div
            v-if="
              product.available > 0 &&
                !(
                  product.productInfos &&
                  product.productInfos.USER_DISABLED == '1'
                )
            "
            class="qty-wrap rounded-pill"
            :class="{ 'not-empty': quantity > 0 }"
            transition="fab-transition"
          >
            <a
              role="button"
              class="minus rounded-circle"
              @click.stop.prevent="minus"
              @mousedown.stop
            >
              <!-- v-ripple -->
              <v-icon aria-label="Aggiungere meno quantità">$minus</v-icon>
            </a>
            <div class="val-cnt">
              <span class="val">{{ quantity }} {{ unit }}</span>
              <span class="small">{{ quantityPerUnit }}</span>
            </div>
            <a
              aria-label="Aggiungi al tuo carrello"
              role="button"
              class="plus rounded-circle"
              @click.stop.prevent="plus"
              @mousedown.stop
            >
              <!-- v-ripple -->
              <v-icon v-if="quantity > 0" aria-label="Aggiungere più quantità"
                >$plus</v-icon
              >
              <v-icon v-else aria-label="Aggiungi al tuo carrello"
                >$cart</v-icon
              >
            </a>
          </div>
          <div v-else class="not-available">
            Il prodotto non è disponibile
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>
<style lang="scss">
.header {
  height: fit-content;
  width: 100%;
  margin-bottom: 20px;
  .icons-box {
    display: flex;
    margin-left: 3px;
  }
}
.not-available {
  color: red;
  font-size: 12px;
  white-space: normal;
  height: auto;
  display: flex;
  align-items: flex-end;
  text-align: right;
}
.heart {
  color: black !important;
}
</style>
<script>
//TODO sistemare la visualizzazione della card su iphone SE dimensione della description
import ProductInfo from "./ProductInfo.vue";
import ProductClass from "./ProductClass.vue";
import ProductAcqLimit from "./ProductAcqLimit.vue";
import ProductPromo from "./ProductPromo.vue";
import ProductPrice from "./ProductPrice.vue";
import LeadTime from "./ProductLeadTime.vue";
import LockCutoff from "./ProductLockCutoff.vue";
import AxBCutoff from "./ProductAxBCutoff.vue";
import DayLock from "./ProductDayLock.vue";
import { mapState, mapGetters } from "vuex";
import productMixin from "~/mixins/product";
import analyticsService from "~/service/analyticsService";

export default {
  name: "ProductCard",
  props: ["product", "position"],
  components: {
    ProductInfo,
    ProductClass,
    ProductAcqLimit,
    ProductPromo,
    ProductPrice,
    LeadTime,
    LockCutoff,
    AxBCutoff,
    DayLock
  },
  mixins: [productMixin],
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    item() {
      return this.getItemByProductId(this.product.productId);
    }
  },
  methods: {
    logClickProduct() {
      analyticsService.clickProduct(this.product, this.position);
    }
  }
};
</script>
