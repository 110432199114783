var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"product-card py-1 fill-height",attrs:{"outlined":"","rounded":"lg","to":{ name: 'Product', params: { slug: _vm.product.slug } }}},[_c('div',{staticClass:"product fill-height",class:[_vm.promoClass],on:{"click":_vm.logClickProduct}},[_c('div',{staticClass:"header d-flex justify-space-between align-start"},[(_vm.product.warehousePromo)?_c('ProductPromo',{attrs:{"warehousePromo":_vm.product.warehousePromo}}):_vm._e(),_c('v-spacer'),_c('div',{staticClass:"icons-box align-center"},[_c('ProductAcqLimit',{attrs:{"product":_vm.product}}),(_vm.product.productInfos.LEAD_TIME)?_c('LeadTime',{attrs:{"time":_vm.product.productInfos.LEAD_TIME,"showTooltip":true,"isCheckout":false}}):_vm._e(),(_vm.product.productInfos.PRODUCT_LOCK_CUTOFF)?_c('LockCutoff',{attrs:{"hours":_vm.product.productInfos.PRODUCT_LOCK_CUTOFF,"showTooltip":true,"isCheckout":false}}):_vm._e(),(_vm.product.productInfos.PRODUCT_AXB_CUTOFF)?_c('AxBCutoff',{attrs:{"hour":_vm.product.productInfos.PRODUCT_AXB_CUTOFF,"showTooltip":true,"isCheckout":false}}):_vm._e(),(_vm.product.productInfos.DAY_LOCK)?_c('DayLock',{attrs:{"days":_vm.product.productInfos.DAY_LOCK,"showTooltip":true,"isCheckout":false}}):_vm._e(),(_vm.product.warehousePromo && _vm.product.warehousePromo.view.bubble)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,false,2860172588)},[_c('span',{staticClass:"tooltip-content-bubble",domProps:{"innerHTML":_vm._s(_vm.product.warehousePromo.view.bubble)}})]):_vm._e(),_c('v-tooltip',{key:_vm.heartKey,attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"aria-label":"Aggiungi ad una lista","icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.addToFavorites.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[(_vm.highlight)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("$heartFull")]):_c('v-icon',{staticClass:"heart"},[_vm._v("$heart")])],1)]}}])},[_c('span',[_vm._v("Aggiungi ad una lista")])])],1)],1),_c('div',{staticClass:"product-classes-wrapper"},[(_vm.product.productInfos.REFUND_ASL == '1')?_c('ProductInfo'):_vm._e(),_vm._l((_vm.productClasses),function(pClass){return _c('ProductClass',{key:pClass.productClassid,attrs:{"pClass":pClass}})})],2),_c('div',{staticClass:"d-flex body flex-column fill-width fill-height"},[_c('img',{staticClass:"product-img align-self-center",attrs:{"src":_vm.product.mediaURL,"width":_vm.$vuetify.breakpoint.xs ? 110 : 170,"height":_vm.$vuetify.breakpoint.xs ? 110 : 170,"alt":'Immagine' + _vm.product.name,"title":_vm.product.name + ' (' + _vm.product.codInt + '-' + _vm.product.codVar + ')'}}),_c('div',{staticClass:"description"},[_c('span',{staticClass:"brand"},[_vm._v(_vm._s(_vm.product.shortDescr))]),_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.product.name))]),_c('span',{staticClass:"descr"},[(
              _vm.product.productInfos.TIPOLOGIA == 'Pezzo' &&
                _vm.product.priceUmDisplay
            )?_c('span',{staticClass:"weight-unit"},[_vm._v(" "+_vm._s(_vm.$n(_vm.product.priceUmDisplay, "currency"))+"/ "),_c('span',{staticClass:"weightUnitDisplay"},[_vm._v(_vm._s(_vm.product.weightUnitDisplay)+" - ")])]):_vm._e(),_vm._v(" "+_vm._s(_vm.product.description)+" ")])]),_c('div',{staticClass:"actions mt-auto"},[_c('ProductPrice',{attrs:{"product":_vm.product}}),(
            _vm.product.available > 0 &&
              !(
                _vm.product.productInfos &&
                _vm.product.productInfos.USER_DISABLED == '1'
              )
          )?_c('div',{staticClass:"qty-wrap rounded-pill",class:{ 'not-empty': _vm.quantity > 0 },attrs:{"transition":"fab-transition"}},[_c('a',{staticClass:"minus rounded-circle",attrs:{"role":"button"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.minus.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},[_c('v-icon',{attrs:{"aria-label":"Aggiungere meno quantità"}},[_vm._v("$minus")])],1),_c('div',{staticClass:"val-cnt"},[_c('span',{staticClass:"val"},[_vm._v(_vm._s(_vm.quantity)+" "+_vm._s(_vm.unit))]),_c('span',{staticClass:"small"},[_vm._v(_vm._s(_vm.quantityPerUnit))])]),_c('a',{staticClass:"plus rounded-circle",attrs:{"aria-label":"Aggiungi al tuo carrello","role":"button"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.plus.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},[(_vm.quantity > 0)?_c('v-icon',{attrs:{"aria-label":"Aggiungere più quantità"}},[_vm._v("$plus")]):_c('v-icon',{attrs:{"aria-label":"Aggiungi al tuo carrello"}},[_vm._v("$cart")])],1)]):_c('div',{staticClass:"not-available"},[_vm._v(" Il prodotto non è disponibile ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }