<template>
  <v-tooltip left>
    <template v-slot:activator="{ on, attrs }">
      <div class="product-class product-info" v-bind="attrs" v-on="on">
        <img aria-hidden alt="LogoAts" :src="src" />
      </div>
    </template>
    <span
      >Rimborsabili ATS: puoi usufruire del rimborso sul prodotto solo
      scegliendo “pagamento al momento del ritiro”</span
    >
  </v-tooltip>
</template>
<style scoped lang="scss">
.product-info {
  img {
    vertical-align: middle;
    width: 25px;
    height: 25px;
  }
}
</style>
<script>
export default {
  name: "ProductInfo",
  computed: {
    src() {
      return "/product-classes/LogoAts.svg";
    }
  }
};
</script>
