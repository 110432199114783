<template>
  <div
    class="product-slider"
    v-if="mode === 'banner' || (products && products.length > 0)"
  >
    <h2 v-if="title" v-html="title"></h2>
    <div>
      <ProposalImage v-if="mode == 'banner' && hasImage" :proposal="proposal" />
      <swiper
        :options="swiperOption"
        ref="swiperRef"
        v-if="products.length > 0"
      >
        <swiper-slide v-if="mode == 'card' && hasImage">
          <ProposalCard :proposal="proposal" />
        </swiper-slide>
        <swiper-slide v-for="product in products" :key="product.productId">
          <ProductCard v-bind:product="product" />
        </swiper-slide>
      </swiper>
    </div>
    <div
      class="swiper-pagination"
      :class="paginationClass"
      slot="pagination"
      v-if="products.length > 1"
    ></div>
  </div>
</template>
<style global lang="scss">
.product-slider {
  // margin-bottom: 30px;
  margin-bottom: 10px;

  position: relative;
  .banner-card {
    width: 100%;
    margin-bottom: 20px;
  }
  .proposal-card {
    width: 220px;
    height: 365px;
    .v-card__text {
      width: auto;
    }
    img {
      padding: 0px;
      align-self: center;
    }
  }
  h2 {
    margin-bottom: 10px;
    font-size: 28px;
    letter-spacing: 0.45px;
    color: #3c3c3c;
    font-weight: bold;
  }
  .swiper-slide {
    width: initial;
  }
  .swiper-pagination {
    bottom: -20px;
    display: flex;
    /* flex-direction: column; */
    position: relative;
    justify-content: center;
    z-index: 4;
  }
  .product-card {
    width: 250px;
    .v-image {
      min-height: 170px;
    }
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .product-card {
      width: 230px !important;
      .v-image {
        min-height: 110px;
      }
    }
    .proposal-card {
      height: 293px;
      width: 170px;
      .v-card__text {
        font-size: 11px;
        padding: 0 5px;
      }
    }
    .product {
      .promo-box {
        font-size: 0.875rem;
      }
    }
  }
}
</style>
<script>
import ProductService from "~/service/productService";
import ProductCard from "@/components/product/ProductCard";
import ProposalCard from "@/components/proposal/ProposalCard";
import ProposalImage from "@/components/proposal/ProposalImage";
import get from "lodash/get";
import analyticsService from "~/service/analyticsService";

export default {
  name: "ProductListSlider",
  props: {
    categoryId: { type: Number, required: false },
    productId: { type: Number, required: false },
    layout: { type: Number, required: false },
    shuffle: { type: Boolean, required: false, default: false },
    promo: { type: Boolean, required: false, default: false },
    limit: { type: Number, required: false, default: 8 },
    title: { type: String, required: false },
    proposal: { type: Object, required: false },
    paginationClass: { type: String, required: false }
  },

  components: { ProductCard, ProposalCard, ProposalImage },
  data() {
    return {
      products: [],
      swiperOption: {
        slidesPerView: "auto",
        slidesPerGroup: 1,
        spaceBetween: 20,
        watchOverflow: true,
        pagination: {
          el: "." + this.paginationClass,
          clickable: true
        },
        breakpoints: {
          600: {
            slidesPerGroup: 1
          },
          960: {
            slidesPerGroup: 3
          },
          1280: {
            slidesPerGroup: 3
          },
          1920: {
            slidesPerGroup: 5
          }
        }
      }
    };
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    if (this.mode == "card") {
      this.fetchProducts();
      if (this.products.length > 0) {
        analyticsService.viewProducts(this.products, this.position);
      }
    }
  },
  computed: {
    hasImage() {
      return (
        this.proposal &&
        this.proposal.img &&
        this.proposal.img != "https://www.iperalspesaonline.it"
      );
    },
    mode() {
      return get(this.proposal, "metaData.category_proposal_type.MODE", "card");
    }
  },
  methods: {
    async fetchProducts() {
      if (this.proposal) {
        let response = await ProductService.search({
          parent_category_id: this.proposal.categoryProposedId,
          page_size: 12
        });
        this.products = response.products;
      } else {
        let response = await ProductService.adv({
          layout: this.layout,
          product_id: this.productId,
          category_id: this.categoryId,
          limit: this.limit,
          shuffle: this.shuffle,
          promo: this.promo
        });
        this.products = response.products;
      }
    }
  }
};
</script>
