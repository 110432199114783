<template>
  <v-card
    class="proposal-card d-flex flex-column justify-space-around"
    rounded="lg"
    elevation="2"
    :style="{
      color: color,
      backgroundColor: backgroundColor
    }"
    :href="link"
    @click.prevent="handleLink"
  >
    <img :src="src" :title="'logo' + proposal.descr" />
    <v-card-text
      v-if="proposal.content"
      class="pre-slide-text"
      v-html="proposal.content"
      :style="{
        color: color
      }"
    ></v-card-text>
  </v-card>
</template>
<style scoped lang="scss"></style>
<script>
import banner from "~/mixins/banner";
import get from "lodash/get";
export default {
  name: "ProposalCard",
  props: ["proposal"],
  mixins: [banner],
  computed: {
    color() {
      return get(this.proposal, "metaData.category_proposal_type.COLOR");
    },
    backgroundColor() {
      return get(this.proposal, "metaData.category_proposal_type.BG_COLOR");
    },
    src() {
      let prop =
        "metaData.category_proposal_type." +
        (this.$vuetify.breakpoint.xs ? "IMAGE_MOBILE" : "IMAGE_DESKTOP");
      return get(this.proposal, prop, this.proposal.img);
    }
  }
};
</script>
